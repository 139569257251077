// ** Initial State
const initialState = {
  data: [],
  total: 0,
  details: {},
  offers: {},
  stats: {},
};

const InfluencerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INFLUENCERS":
      return {
        ...state,
        ...action
      };
    case "INFLUENCER_OFFERS":

      return {

        ...state,
        ...action
      };
    case "INFLUENCER_DETAILS":

      return {

        ...state,
        ...action
      };
    case "INFLUENCER_STATUS":
      return {

        ...state,
        details:{
          ...state.details,
          status:action.status
        }
      };
    default:
      return state;
  }
};

export default InfluencerReducer;
