// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

// ** Initial State
const initialState = {
  socialMedias: [],
  categories: [],
  business_types: [],
  cities: [],
  properties:[],
  conditions:[],
  adTypes:[],
 }
 const initialStateNotification = {
  total_unread:0
 }

export const   generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_GENERAL_DATA':
      return { ...state, 
        socialMedias: action.payload?.socialMedias,
         categories: action.payload?.categories ,
         business_types: action.payload?.business_types , 
         cities: action.payload?.cities,
          properties: action.payload?.properties,
          conditions: action.payload?.conditions,
          adTypes: action.payload?.adTypes 
        }
  
    default:
      return state
  }
}
export const   generalNotificationsReducer = (state = initialStateNotification, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS':
      return { ...state, total_unread: action.payload?.total_unread }
  
    default:
      return state
  }
}

