// ** Initial State
const initialState = {
  rules: {},
  categories: [],
  cities: [],
  conditions: [],
  businessTypes: [],
  advertisers_profits_rules: [],
  influencers_profits_rules: [],
  tax: 0,
  general_data: {
    show_coupons: true,
    show_social_medias: true,
    show_categories: true,
  },
  versions: {
    company_android_version: '',
    company_ios_version: '',
    influencers_android_version: '',
    influencers_ios_version: '',
  },
};

const RulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RULES":
      return {
        ...state,
        ...action
      };
    case "CATEGORIES":
      return {
        ...state,
        ...action
      };
    case "ADD_CATEGORIES":
      let categoriesCopy = [...state.categories];
      categoriesCopy.unshift(action.categories)

      return {

        ...state,
        categories: categoriesCopy
      };
    case "UPDATE_CATEGORIES":

      const categoryIndex = state.categories.find((s) => s._id == action.categories._id);
      if (categoryIndex) {
        Object.assign(categoryIndex, action.categories);
      }


      return {

        ...state,
        categories: [...state.categories]
      };

    case "CITIES":
      return {
        ...state,
        ...action
      };
    case "ADD_CITIES":
      let citiesCopy = [...state.cities];
      citiesCopy.unshift(action.cities)

      return {

        ...state,
        cities: citiesCopy
      };
    case "UPDATE_CITIES":

      const citiesIndex = state.cities.find((s) => s._id == action.cities._id);
      if (citiesIndex) {
        Object.assign(citiesIndex, action.cities);
      }


      return {

        ...state,
        cities: [...state.cities]
      };

    case "BUSINESS_TYPES":
      return {
        ...state,
        ...action
      };
    case "ADD_BUSINESS_TYPES":
      let businessTypesCopy = [...state.businessTypes];
      businessTypesCopy.unshift(action.businessTypes)

      return {

        ...state,
        businessTypes: businessTypesCopy
      };
    case "UPDATE_BUSINESS_TYPES":

      const businessTypeIndex = state.businessTypes.find((s) => s._id == action.businessTypes._id);
      if (businessTypeIndex) {
        Object.assign(businessTypeIndex, action.businessTypes);
      }


      return {

        ...state,
        businessTypes: [...state.businessTypes]
      };
      case "CONDITIONS":
      return {
        ...state,
        ...action
      };
    case "ADD_CONDITIONS":
      let conditionsCopy = [...state.conditions];
      conditionsCopy.unshift(action.conditions)

      return {

        ...state,
        conditions: conditionsCopy
      };
    case "UPDATE_CONDITIONS":

      const conditionsIndex = state.conditions.find((s) => s._id == action.conditions._id);
      if (conditionsIndex) {
        Object.assign(conditionsIndex, action.conditions);
      }


      return {

        ...state,
        conditions: [...state.conditions]
      };

    default:
      return state;
  }
};

export default RulesReducer;
